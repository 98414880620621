import { gql } from 'graphql-tag'

export const ANALYTICS_GET_OPTIONS = gql`
  query ANALYTICS_GET_OPTIONS(
    $dimension: String!
    $filters: [QueryFilter!]
    $scopeType: ScopeType!
  ) {
    analyticsGetOptions(
      dimension: $dimension
      filters: $filters
      scopeType: $scopeType
    )
  }
`

export enum RenamingType {
  EMPLOYEE_DIMENSION = 'EMPLOYEE_DIMENSION',
  RECRUITMENT_DIMENSION = 'RECRUITMENT_DIMENSION',
  RECRUITMENT_INTERVIEW = 'RECRUITMENT_INTERVIEW',
  RECRUITMENT_TIME_TO = 'RECRUITMENT_TIME_TO'
}

export enum RenamingField {
  EMPLOYEE_SUBORGANIZATION = 'EMPLOYEE_SUBORGANIZATION',
  EMPLOYEE_DEPARTMENT = 'EMPLOYEE_DEPARTMENT',
  EMPLOYEE_LOCATION_OFFICE = 'EMPLOYEE_LOCATION_OFFICE',
  EMPLOYEE_LOCATION_REMOTE = 'EMPLOYEE_LOCATION_REMOTE',
  EMPLOYEE_CAREER_LEVEL = 'EMPLOYEE_CAREER_LEVEL',
  EMPLOYEE_CAREER_SUB_LEVEL = 'EMPLOYEE_CAREER_SUB_LEVEL',
  EMPLOYEE_GROUP_1 = 'EMPLOYEE_GROUP_1',
  EMPLOYEE_GROUP_2 = 'EMPLOYEE_GROUP_2',
  EMPLOYEE_GROUP_3 = 'EMPLOYEE_GROUP_3',
  EMPLOYEE_LOCATION_1 = 'EMPLOYEE_LOCATION_1',
  EMPLOYEE_LOCATION_2 = 'EMPLOYEE_LOCATION_2',

  RECRUITMENT_JOB_SUBORGANIZATION = 'RECRUITMENT_JOB_SUBORGANIZATION',
  RECRUITMENT_JOB_DEPARTMENT = 'RECRUITMENT_JOB_DEPARTMENT',
  RECRUITMENT_JOB_GROUP_1 = 'RECRUITMENT_JOB_GROUP_1',
  RECRUITMENT_JOB_GROUP_2 = 'RECRUITMENT_JOB_GROUP_2',
  RECRUITMENT_JOB_GROUP_3 = 'RECRUITMENT_JOB_GROUP_3',
  RECRUITMENT_JOB_LOCATION_1 = 'RECRUITMENT_JOB_LOCATION_1',

  RECRUITMENT_INTERVIEW_1 = 'RECRUITMENT_INTERVIEW_1',
  RECRUITMENT_INTERVIEW_2 = 'RECRUITMENT_INTERVIEW_2',
  RECRUITMENT_INTERVIEW_3 = 'RECRUITMENT_INTERVIEW_3',
  RECRUITMENT_INTERVIEW_4 = 'RECRUITMENT_INTERVIEW_4',

  RECRUITMENT_TIME_TO_OFFER = 'RECRUITMENT_TIME_TO_OFFER',
  RECRUITMENT_TIME_TO_HIRE = 'RECRUITMENT_TIME_TO_HIRE',
  RECRUITMENT_TIME_TO_FIRST_FILL = 'RECRUITMENT_TIME_TO_FIRST_FILL',
  RECRUITMENT_TIME_TO_FILL_AND_ARCHIVE = 'RECRUITMENT_TIME_TO_FILL_AND_ARCHIVE'
}

export type Renaming = {
  field: RenamingField
  naming: string
}

import { ANALYTICS_GET_META } from '~/gql/analytics/analyticsGetMeta'
import { UserRole } from '~/types/permissions'
import { useCurrentUserStore } from '~/stores/currentUser'
import { useScopeStore } from '~/stores/scope'
import { type Meta, type Dimension, type Measure } from '~/types/cube'

interface State {
  meta: Meta | null
  measuresMeta: Measure[] | null
  dimensionsMeta: Dimension[] | null
}

export const useCubeStore = defineStore('cube', {
  state: (): State => ({
    meta: null,
    measuresMeta: null,
    dimensionsMeta: null
  }),
  actions: {
    async loadMeta() {
      const currentUser = useCurrentUserStore()
      // TODO DEV-960 - Fix this - Meta updating should be processed only if meta are null or scopeType are modified (no exception for admin only)
      if (currentUser.role === UserRole.ADMIN && this.meta) return

      const app = useNuxtApp()
      const { scopeType } = useScopeStore()

      const meta = (
        await app.$apiGqlClient.query({
          query: ANALYTICS_GET_META,
          variables: { scopeType }
        })
      ).data.analyticsGetMeta as Meta

      const measuresMeta = meta.cubes.map(cube => cube.measures).flat()

      const dimensionsMeta = meta.cubes.map(cube => cube.dimensions).flat()

      this.meta = meta
      this.measuresMeta = measuresMeta
      this.dimensionsMeta = dimensionsMeta
    }
  }
})

import { gql } from 'graphql-tag'

export const UPDATE_USER_FILTER_TRACKING_MUTATION = gql`
  mutation UPDATE_USER_FILTER_TRACKING_MUTATION(
    $filter: QueryFilter!
    $context: AppContext!
  ) {
    updateUserFilterTracking(filter: $filter, context: $context) {
      id
    }
  }
`

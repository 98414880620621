import { gql } from 'graphql-tag'

const BDESE_FIELDS = `
  id
  year
  createdAt
  publishedAt
  bdeseSections {
    id
    order
    content
    title
    description
    isPublished
  }
  bdeseFilters {
    member
    operator
    values
  }
  realizedYearsNumber
  trendYearsNumber
  isFiscal
`

export const BDESE_FRAGMENT = gql`
  fragment BdeseFields on Bdese {
    ${BDESE_FIELDS}
  }
`

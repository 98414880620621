import { gql } from 'graphql-tag'

export const ANALYTICS_GET_QUERY = gql`
  query ANALYTICS_GET_QUERY(
    $query: AnalyticsQueryDto!
    $scopeType: ScopeType!
  ) {
    analyticsGetQuery(query: $query, scopeType: $scopeType) {
      queryType
      pivotQuery {
        measures
        dimensions
        timeDimensions {
          dimension
          granularity
          dateRange
          compareDateRange
        }
        filters {
          member
          operator
          values
        }
        limit
        offset
      }
      results {
        data
        total
      }
    }
  }
`

import { gql } from 'graphql-tag'

export const UPDATE_DASHBOARD_DEFAULT_FILTERS_MUTATION = gql`
  mutation updateDashboardDefaultFilters(
    $dashboard: String!
    $newFilters: [DashboardDefaultFilterDto!]!
  ) {
    updateDashboardDefaultFilters(
      dashboard: $dashboard
      newFilters: $newFilters
    ) {
      filter {
        id
        member
        operator
        values
      }
      dashboard
    }
  }
`

import { gql } from 'graphql-tag'
import { BDESE_FRAGMENT } from './bdeseFragment'

export const GET_BDESE_QUERY = gql`
  ${BDESE_FRAGMENT}
  query GET_BDESE($id: String!) {
    getBdese(id: $id) {
      ...BdeseFields
    }
  }
`

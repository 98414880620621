import { PeriodType, type Period } from '~/types/timeDimension'

export enum PERIOD_ID {
  FISCAL_LAST_YEAR = 'FISCAL_LAST_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  FISCAL_CURRENT_YEAR = 'FISCAL_CURRENT_YEAR',
  CURRENT_YEAR = 'CURRENT_YEAR',
  FISCAL_NEXT_YEAR = 'FISCAL_NEXT_YEAR',
  NEXT_YEAR = 'NEXT_YEAR',
  FISCAL_LAST_YEAR_Q1 = 'FISCAL_LAST_YEAR_Q1',
  LAST_YEAR_Q1 = 'LAST_YEAR_Q1',
  FISCAL_LAST_YEAR_Q2 = 'FISCAL_LAST_YEAR_Q2',
  LAST_YEAR_Q2 = 'LAST_YEAR_Q2',
  FISCAL_LAST_YEAR_Q3 = 'FISCAL_LAST_YEAR_Q3',
  LAST_YEAR_Q3 = 'LAST_YEAR_Q3',
  FISCAL_LAST_YEAR_Q4 = 'FISCAL_LAST_YEAR_Q4',
  LAST_YEAR_Q4 = 'LAST_YEAR_Q4',
  FISCAL_CURRENT_YEAR_Q1 = 'FISCAL_CURRENT_YEAR_Q1',
  CURRENT_YEAR_Q1 = 'CURRENT_YEAR_Q1',
  FISCAL_CURRENT_YEAR_Q2 = 'FISCAL_CURRENT_YEAR_Q2',
  CURRENT_YEAR_Q2 = 'CURRENT_YEAR_Q2',
  FISCAL_CURRENT_YEAR_Q3 = 'FISCAL_CURRENT_YEAR_Q3',
  CURRENT_YEAR_Q3 = 'CURRENT_YEAR_Q3',
  FISCAL_CURRENT_YEAR_Q4 = 'FISCAL_CURRENT_YEAR_Q4',
  CURRENT_YEAR_Q4 = 'CURRENT_YEAR_Q4',
  CURRENT_MONTH = 'CURRENT_MONTH',
  MTD = 'MTD',
  FISCAL_NEXT_YEAR_Q1 = 'FISCAL_NEXT_YEAR_Q1',
  NEXT_YEAR_Q1 = 'NEXT_YEAR_Q1',
  FISCAL_QTD = 'FISCAL_QTD',
  QTD = 'QTD',
  FISCAL_YTD = 'FISCAL_YTD',
  YTD = 'YTD',
  LAST_12_MONTHS_TO_DATE = 'LAST_12_MONTHS_TO_DATE',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
  ALL_TIME = 'ALL_TIME'
}

export const ALL_TIME_PERIOD: Period = {
  periodId: PERIOD_ID.ALL_TIME,
  periodType: PeriodType.YEAR,
  dateRange: ['2019-01-01', '2029-12-31'],
  isFuture: true,
  compareDateRange: [],
  trendDateRange: []
}

import { useScopeStore } from './scope'
import { useTimeDimensionStore } from './timeDimension'
import { useFiltersStore } from './filters'
import { useBdeseStore } from './bdese'
import { useCustomizationStore } from './customization'
import { getMainCubeFromQuery } from '~/services/explore'
import { DataType, type Filter } from '~/types/analytics'
import { AnalyticsCube, CUBE_DATA_TYPES } from '~/constants/cube'
import type { ScopeType } from '~/types/permissions'
import type { FullPeriod } from '~/types/timeDimension'
import type { Query } from '~/types/query'
import { ROUTE } from '~/constants/routes'

interface State {
  playedQueries: { [id: string]: Query }
}

export const useAnalyticsStore = defineStore('analytics', {
  state: (): State => ({ playedQueries: {} }),
  actions: {
    addOrUpdateQuery(id: string, query: Query) {
      this.playedQueries = { ...this.playedQueries, [id]: { ...query } }
    },
    removeQuery(id: string) {
      delete this.playedQueries[id]
    }
  },
  getters: {
    usableCubes(state: State): AnalyticsCube[] {
      const usedCubes = [
        ...new Set(
          Object.values(state.playedQueries)
            .map(query => getMainCubeFromQuery(query))
            .filter(cube => cube) as AnalyticsCube[]
        )
      ]

      if (
        usedCubes.some(cube => CUBE_DATA_TYPES[cube] === DataType.EMPLOYEE) &&
        !usedCubes.includes(AnalyticsCube.EMPLOYMENT_DAILY_SNAPSHOTS)
      ) {
        return [...usedCubes, AnalyticsCube.EMPLOYMENT_DAILY_SNAPSHOTS]
      } else {
        return usedCubes
      }
    },
    usableDataTypes(_: State): DataType[] {
      if (this.usableCubes.length === 0) return Object.values(DataType)
      return [
        ...new Set(
          this.usableCubes
            .map(cube => CUBE_DATA_TYPES[cube])
            .filter(dataType => dataType !== null)
        )
      ] as DataType[]
    },
    analyticsParams(_: State): {
      scopeType: ScopeType
      selectedPeriod: FullPeriod
      allAppliedFilters: Filter[]
    } {
      const { scopeType } = useScopeStore()
      const { selectedPeriod } = useTimeDimensionStore()
      const { allAppliedFilters } = useFiltersStore()

      return {
        scopeType,
        selectedPeriod,
        allAppliedFilters
      }
    },
    isInFiscalMode(_: State): boolean {
      const { path } = useRoute()

      if (path.includes(ROUTE.BDESE)) {
        return !!useBdeseStore().bdese?.isFiscal
      } else {
        return useCustomizationStore().isDashboardInFiscalMode
      }
    }
  }
})

import { GET_BDESE_QUERY } from '~/gql/legalReports/bdese/getBdeseQuery'
import type { Bdese } from '~/types/bdese'

interface State {
  bdese: Bdese | null
  isDraftVersion: boolean
}

export const useBdeseStore = defineStore('bdese', {
  state: (): State => ({
    bdese: null,
    isDraftVersion: false
  }),
  getters: {
    hasEditorAccess: (state: State) => {
      if (!state.bdese) return false
      return state.bdese.bdeseSections.some(section => !section.isPublished)
    }
  },
  actions: {
    async getBdese(id: string) {
      const { $apiGqlClient } = useNuxtApp()

      const bdese = (
        await $apiGqlClient.query({
          query: GET_BDESE_QUERY,
          variables: { id }
        })
      ).data.getBdese as Bdese

      this.isDraftVersion = bdese.bdeseSections.some(
        section => !section.isPublished
      )
      this.bdese = bdese
    },
    updateBdese(bdese: Bdese, displayDraftVersion: boolean = true) {
      this.isDraftVersion = displayDraftVersion
      this.bdese = bdese
    },
    toggleIsDraftVersion() {
      this.isDraftVersion = !this.isDraftVersion
    }
  }
})

import { gql } from 'graphql-tag'

export const FETCH_DASHBOARD_DEFAULT_FILTERS_QUERY = gql`
  query FETCH_DASHBOARD_DEFAULT_FILTERS_QUERY {
    getDashboardDefaultFilters {
      filter {
        id
        member
        operator
        values
      }
      dashboard
    }
  }
`

import { useFiltersStore } from '~/stores/filters'
import { useCustomizationStore } from '~/stores/customization'
import {
  getCurrentQuarterPeriodOptions,
  buildCompletePeriodFromPeriodOption,
  getPeriodFromDateRange,
  isPeriodInvalid
} from '~/services/period'
import {
  type FullPeriod,
  type Period,
  PeriodType,
  TimeGrain
} from '~/types/timeDimension'
import { replaceRouteQueryParam } from '~/services/router'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { ALL_TIME_PERIOD, PERIOD_ID } from '~/constants/period'
import { formatDate2 } from '~/services/date'

interface State {
  selectedPeriod: FullPeriod
  options: Period[]
}

export const useTimeDimensionStore = defineStore('timeDimension', {
  state: (): State => {
    return {
      selectedPeriod: {
        periodId: PERIOD_ID.QTD,
        periodType: PeriodType.QUARTER,
        dateRange: [],
        isFuture: false,
        compareDateRange: [],
        trendDateRange: [],
        trendSubPeriod: TimeGrain.MONTH,
        chartSubPeriod: TimeGrain.WEEK
      },
      options: []
    }
  },
  actions: {
    async setSelectedPeriodFromPeriodId(
      periodId: string | undefined = undefined,
      shouldModifyUrlParams = true
    ) {
      periodId = periodId || this.getDefaultPeriodId()

      const periodOption = this.options.find(
        periodOption => periodOption.periodId === periodId
      )!

      const newPeriod = buildCompletePeriodFromPeriodOption(periodOption)

      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [QUERY_PARAMETERS.START_DATE, newPeriod.dateRange[0]],
          [QUERY_PARAMETERS.END_DATE, newPeriod.dateRange[1]]
        ]))

      this.selectedPeriod = newPeriod
      const filtersStore = useFiltersStore()
      await filtersStore.getStandardFiltersOptions()
    },
    async setSelectedPeriodFromDateRange({
      dateRange,
      shouldModifyUrlParams = true
    }: {
      dateRange: string[]
      shouldModifyUrlParams?: boolean
    }) {
      const newPeriod = getPeriodFromDateRange(dateRange, this.options)

      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [QUERY_PARAMETERS.START_DATE, newPeriod.dateRange[0]],
          [QUERY_PARAMETERS.END_DATE, newPeriod.dateRange[1]]
        ]))

      this.selectedPeriod = newPeriod
      const filtersStore = useFiltersStore()
      await filtersStore.getStandardFiltersOptions()
    },
    async generateSelectedDateRange(withAllTimeOption: boolean = false) {
      const route = useRoute()

      this.options = [
        ...(withAllTimeOption ? [ALL_TIME_PERIOD] : []),
        ...getCurrentQuarterPeriodOptions()
      ]

      if (
        !route.query[QUERY_PARAMETERS.START_DATE] ||
        !route.query[QUERY_PARAMETERS.END_DATE] ||
        isPeriodInvalid(route.query.start as string, route.query.end as string)
      ) {
        await this.setSelectedPeriodFromPeriodId()
        return
      }

      const startDate = formatDate2(route.query.start as string)
      const endDate = formatDate2(route.query.end as string)

      await this.setSelectedPeriodFromDateRange({
        dateRange: [startDate, endDate],
        shouldModifyUrlParams: false
      })
    },
    getDefaultPeriodId() {
      if (this.options.find(option => option.periodId === PERIOD_ID.ALL_TIME)) {
        return PERIOD_ID.ALL_TIME
      } else {
        const { isDashboardInFiscalMode } = useCustomizationStore()
        return isDashboardInFiscalMode ? PERIOD_ID.FISCAL_QTD : PERIOD_ID.QTD
      }
    }
  }
})

import { gql } from 'graphql-tag'

export const ANALYTICS_GET_META = gql`
  query ANALYTICS_GET_META($scopeType: String!) {
    analyticsGetMeta(scopeType: $scopeType) {
      cubes {
        name
        public
        title
        joins {
          name
          relationship
        }
        measures {
          name
          title
          type
          shortTitle
          aggType
          isVisible
          drillMembers
          meta {
            category
            format
            listAndKpiFormat
            permissions
            currentUserAccessType
            shouldUseTimeGranularity
            showInExplorer
            showInBdeseExplorer
            priority
            isFavorable
            associatedMeasure
            fixedDrillMembers
            includeMeasureAsDrillMember
            isBenchmarkable
            canBeGrouped
            canNotBeRequestedWithTimeDimensions
            renamingFields
            normalizationFieldEntries
            orderBy
          }
        }
        dimensions {
          name
          type
          shortTitle
          title
          isVisible
          meta {
            category
            permissions
            currentUserAccessType
            format
            listAndKpiFormat
            showInTableExplorer
            showInGenericExplorer
            showInBdeseGenericExplorer
            showInFilter
            showInBdeseFilter
            showInStandardFilter
            showInUserScopeFilter
            showInBdeseScopeFilter
            priority
            renamingFields
            normalizationFieldEntries
            keepOptionsInFilter
          }
        }
      }
    }
  }
`

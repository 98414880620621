import { DIMENSION_TITLES } from '~/constants/analytics'
import { TimeGrain } from '~/types/timeDimension'

export const MAIN_DEFAULT_TIME_DIMENSION_TITLES = [
  DIMENSION_TITLES.DATE_DAY,
  DIMENSION_TITLES.DATE_WEEK,
  DIMENSION_TITLES.DATE_MONTH,
  DIMENSION_TITLES.DATE_QUARTER,
  DIMENSION_TITLES.DATE_YEAR
] as const

export const MAIN_FISCAL_TIME_DIMENSION_TITLES = [
  DIMENSION_TITLES.DATE_FISCAL_QUARTER,
  DIMENSION_TITLES.DATE_FISCAL_YEAR
] as const

export const ALL_MAIN_TIME_DIMENSION_TITLES = [
  ...MAIN_DEFAULT_TIME_DIMENSION_TITLES,
  ...MAIN_FISCAL_TIME_DIMENSION_TITLES
] as const

export const SECONDARY_DEFAULT_TIME_DIMENSION_TITLES = [
  DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR,
  DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR,
  DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR,
  DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER,
  DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH,
  DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK
] as const

export const SECONDARY_FISCAL_TIME_DIMENSION_TITLES = [
  DIMENSION_TITLES.DATE_FISCAL_MONTH_OF_THE_FISCAL_YEAR,
  DIMENSION_TITLES.DATE_FISCAL_QUARTER_OF_THE_FISCAL_YEAR,
  DIMENSION_TITLES.DATE_FISCAL_DAY_OF_THE_FISCAL_YEAR
] as const

export const ALL_SECONDARY_TIME_DIMENSION_TITLES = [
  ...SECONDARY_DEFAULT_TIME_DIMENSION_TITLES,
  ...SECONDARY_FISCAL_TIME_DIMENSION_TITLES
] as const

export const ALL_DEFAULT_TIME_DIMENSION_TITLES = [
  ...MAIN_DEFAULT_TIME_DIMENSION_TITLES,
  ...SECONDARY_DEFAULT_TIME_DIMENSION_TITLES
] as const

export const ALL_FISCAL_TIME_DIMENSION_TITLES = [
  ...MAIN_FISCAL_TIME_DIMENSION_TITLES,
  ...SECONDARY_FISCAL_TIME_DIMENSION_TITLES
] as const

export const ALL_TIME_DIMENSION_TITLES = [
  ...ALL_DEFAULT_TIME_DIMENSION_TITLES,
  ...ALL_FISCAL_TIME_DIMENSION_TITLES
] as const

export const TIME_GRAIN_BY_TIME_DIMENSION: {
  [key in (typeof ALL_TIME_DIMENSION_TITLES)[number]]: TimeGrain
} = {
  [DIMENSION_TITLES.DATE_DAY]: TimeGrain.DAY,
  [DIMENSION_TITLES.DATE_WEEK]: TimeGrain.WEEK,
  [DIMENSION_TITLES.DATE_MONTH]: TimeGrain.MONTH,
  [DIMENSION_TITLES.DATE_QUARTER]: TimeGrain.QUARTER,
  [DIMENSION_TITLES.DATE_YEAR]: TimeGrain.YEAR,
  [DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR]: TimeGrain.QUARTER,
  [DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR]: TimeGrain.MONTH,
  [DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER]: TimeGrain.MONTH,
  [DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR]: TimeGrain.DAY,
  [DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH]: TimeGrain.DAY,
  [DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK]: TimeGrain.DAY,
  [DIMENSION_TITLES.DATE_FISCAL_DAY_OF_THE_FISCAL_YEAR]: TimeGrain.DAY,
  [DIMENSION_TITLES.DATE_FISCAL_MONTH_OF_THE_FISCAL_YEAR]: TimeGrain.MONTH,
  [DIMENSION_TITLES.DATE_FISCAL_QUARTER]: TimeGrain.QUARTER,
  [DIMENSION_TITLES.DATE_FISCAL_QUARTER_OF_THE_FISCAL_YEAR]: TimeGrain.QUARTER,
  [DIMENSION_TITLES.DATE_FISCAL_YEAR]: TimeGrain.YEAR
}

export const ORDERED_TIME_GRAINS = [
  TimeGrain.DAY,
  TimeGrain.WEEK,
  TimeGrain.MONTH,
  TimeGrain.QUARTER,
  TimeGrain.YEAR
] as const

export const ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION: {
  [key in (typeof ALL_MAIN_TIME_DIMENSION_TITLES)[number]]: (typeof ALL_SECONDARY_TIME_DIMENSION_TITLES)[number][]
} = {
  [DIMENSION_TITLES.DATE_DAY]: [],
  [DIMENSION_TITLES.DATE_WEEK]: [DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK],
  [DIMENSION_TITLES.DATE_MONTH]: [DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH],
  [DIMENSION_TITLES.DATE_QUARTER]: [DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER],
  [DIMENSION_TITLES.DATE_YEAR]: [
    DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR,
    DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR,
    DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR
  ],
  [DIMENSION_TITLES.DATE_FISCAL_QUARTER]: [],
  [DIMENSION_TITLES.DATE_FISCAL_YEAR]: [
    DIMENSION_TITLES.DATE_FISCAL_QUARTER_OF_THE_FISCAL_YEAR,
    DIMENSION_TITLES.DATE_FISCAL_MONTH_OF_THE_FISCAL_YEAR,
    DIMENSION_TITLES.DATE_FISCAL_DAY_OF_THE_FISCAL_YEAR
  ]
}

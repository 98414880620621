import { gql } from 'graphql-tag'

export const ANALYTICS_GET_FILTER_OPERATORS_FOR_MEMBER = gql`
  query ANALYTICS_GET_FILTER_OPERATORS_FOR_MEMBER(
    $memberTitle: String!
    $memberType: String!
    $isScopeFilter: Boolean!
  ) {
    analyticsGetFilterOperatorsForMember(
      memberTitle: $memberTitle
      memberType: $memberType
      isScopeFilter: $isScopeFilter
    ) {
      name
      title
    }
  }
`
